<template>
  <b-container>
    <b-card class="for-margin-top">
      <p
        v-if="storeInfo.policies.length > 0"
        class="about-merchant-policies-privacy text-black"
      >
        {{ storeInfo.policies }}
      </p>
      <p
        v-else
        class="about-merchant-policies-privacy text-black text-center font-weight-bold"
      >
        لا يوجد
      </p>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: "privacyPolicyRoutes",
  props: ["storeInfo"],
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 2px !important;
}
.about-merchant-policies-privacy {
  font-weight: 500;
  font-size: 1.1rem;
  padding: 16px 20px;
  margin-top: revert;
}
@media (max-width: 992px) {
  .about-merchant-policies-privacy {
    font-size: 16px !important;
    padding: 16px 20px;
    margin-top: revert;
  }
}
</style>
